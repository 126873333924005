.select-list-title {
  font-weight: bold;
  padding: 8px;
}

.select-list {
  border: solid 1px;
  font-family: Consolas;
  width: 220px;
  text-align: left;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 0px;
}

.select-list-icon {
  height: 16px;
  width: 16px;
}

.select-card {
  //background: #EEEEEE;
  width: 100%;
  font-weight: bold;
  padding: 8px;
}

.select-card-item {
  border: solid 1px;
  font-family: Consolas;
  width: 180px;
  height: 80px;
  margin: 20px;
  text-align: left;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 0px;
  align-content: center;
}

.select-card-icon {
  margin: 8px;
  height: 40px;
  width: 40px;
}

.list-icon {
  min-width: 24px !important;
  height: 24px !important;
  width: 24px !important;
  margin-right: 4px;
  filter: invert(46%) sepia(8%) saturate(4239%) hue-rotate(166deg) brightness(91%) contrast(89%);
}

.code-error.form-control:disabled,
.code-error.form-control[readonly] {
  font-weight: bold;
  font-size: 11px;
  font-family: monospace;
  border: 1px solid;
}


.page-footer,
.page-footer-right,
.page-footer-left {
  position: fixed;
  clear: both;
  left: auto;
  right: auto;
  bottom: 0px;
  padding: 8px 8px;
  height: 40px;
  width: auto;
}
/*
.page-footer-left {
  width: 50%;
}*/

.page-footer-right {
  right: 0px;
  /*width: 50%;*/
}


.dialog-header {
  height: 24px;
}

.dialog-body {
  height: calc(100% - 76px);
  padding: 16px 16px 8px 16px;
  overflow-y: auto;
}

.dialog-footer {
  height: 52px;
  padding: 4px 16px 0px 16px;
}

.highcharts-text-outline {
  font-size: 1.04em;
}

//Dashboard
.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
  vertical-align: text-top;
}

.float-top-left-spinner {
  left: 8px;
  top: 8px;
  position: absolute;
  z-index: 100;
  margin: 0px;
  padding: 32px;
  border: 1px solid;
  border-radius: 2px;
  text-align: center;
}

.float-top-right-spinner {
  right: 8px;
  top: 4px;
  position: absolute;
  z-index: 100;
  margin: 0px;
}

.float-top-right {
  right: 40px;
  top: 20px;
  position: absolute;
  z-index: 2;
}

.float-top-left {
  left: 8px;
  top: 8px;
  position: absolute;
  z-index: 100;
  margin: 8px;
}

.dashboard-container {
  padding: 0px;
  margin: 0px -10px;
}

.row-counter {
  display: flex;
  justify-content: space-between;
}

.dash-card-back {
  height: 100px;
  width: 240px;
  min-height: 100px;
  min-width: 240px;
  border: 1px solid;
  border-radius: 2px;
  margin: 8px;
  padding: 10px 0px;
}

.dash-card-left {
  float: left;
  width: 80px;
  height: 80px;
}

.dash-card-icon {
  text-align: center;
  margin-top: 0px;
  font-size: 40px;
  height: 60px;
  width: 60px;
  margin: 8px;
  border-radius: 30px;
}

.dash-card-title {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
}

.dash-card-text {
  font-size: 12px;
  text-align: center;
}

.dash-card-small-title {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.dash-lite {
  height: 100px;
  width: 240px;
  min-height: 100px;
  min-width: 240px;
}

.dash-small .float-top-left-spinner,
.dash-small {
  height: 200px;
  width: 321px;
  min-height: 100px;
  min-width: 240px;
}

.dash-medium .float-top-left-spinner,
.dash-medium {
  height: 250px;
  width: 496px;
  min-height: 100px;
  min-width: 240px;
}

.dash-big .float-top-left-spinner,
.dash-big {
  height: 300px;
  width: 1008px;
  min-height: 300px;
  min-width: 600px;
}

.dash-chart {
  width: 100%;
  height: 100%;
  display: block;
}

.dash-trend-ticket {
  text-align: center;
  float: left;
  font-size: 20px;
  width: 106px;
}

.dash-trend-label {
  text-align: center;
  font-size: 12px;
  margin-top: -4px;
  margin-bottom: -6px;
}

.centered {
  margin: 0 auto;
}

.no-padding,
.nopadding {
  margin: 0px;
  padding: 0px;
}

.edit-row {
  margin: 0px 0px 4px 0px;
  padding: 0px;
}

.select-range {
  float: left;
}

.current_date {
  display: block;
  float: right;
  padding-right: 10px;
}
//End dashboard

//Rules & alert
.legend {
  font-size: 10px;
  margin-bottom: 0px;
}

.panel-header,
.panel-header-disabled {
  padding: 0px 8px;
  border: 1px solid;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.panel-body {
  border: 1px solid;
  border-top: none;
}
//End rules & alert

//Parameter
.form-control.filter-control {
  height: 22px;
}

.filter {
  //background-color: #F2F7FD;
  margin-right: 8px;
  height: 30px;
  /* width: 200px;*/
  border: none;
  /*border-bottom: 1px solid #505152;*/
  padding: 0;
  display: inline-block;
}

.filter-select {
  height: 30px;
  width: 200px;
  border: none;
  border-bottom: 1px solid /*#505152*/;
  padding: 0;
  display: inline-block;
}
//End parameter

// Workflow
.rule-pane {
  border: 1px solid;
  border-radius: 2px;
  margin: 0px;
  padding: 16px;
  width: 100%;
}

.work-pane {
  // margin: 16px 8px;
  // padding: 8px;
  width: 100%;
}

.work-pane-title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2px;
}

.work-pane-button {
  height: 50px;
  align-self: center;
  width: 300px;
  border: 0;
  font-weight: bold;
  font-size: 16px;
  padding: 0;
  margin: 6px 18px 6px 0px;
}

.work-pane-button:hover {
  filter: brightness(0.95);
}
//End worflow

// Tables
.table {
  border-collapse: collapse;
  padding: 4px;
}

tr.hovered-row:hover td {
  border-bottom: solid 1px /*red*/;
  font-weight: bold;
}

.table td,
.table th {
  border: 1px solid /*gray*/;
  padding: 4px;
  vertical-align: middle;
}

/**/
table.compact td,
table.compact th {
  padding: 0px 4px;
  font-size: 10px;
}

table.compact th {
  font-size: 10px;
}

table.compact td .btn,
table.compact th .btn {
  font-size: 10px;
  width: 24px;
  height: 24px;
  padding: 0px 4px;
}

.debug,
table.debug,
table.debug th,
table.debug td {
  font-family: 'Consolas';
  font-size: 9.5px;
}


table.scrollable thead {
  border: 1px;
  display: block;
  overflow-y: scroll;
}

table.scrollable tbody {
  overflow-y: scroll;
  overflow-x: hidden;
  display: block;
}

td button.btn,
th button.btn {
  padding: 0px 8px;
  width: 30px;
}


.blueprint {
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0;
  z-index: 0;
}

.blueprint-image {
  max-width: 100%;
  max-height: 100%;
  z-index: 1;
}

.device {
  left: 0px;
  top: 0px;
  z-index: 2;
  position: absolute;
  cursor: pointer;
  text-align: center;
}


.crumb {
  float: left;
  align-content: center;
  text-align: center;
  width: 160px;
  border: 1px solid;
  margin: auto;
  //margin: 0px 8px 0px 0px;
}
//End tables



//Pictures
/* profile picture user */
////////////////////////
.profile_picture {
  width: 60px;
  height: 60px;
  -webkit-clip-path: circle(50.0% at 50% 50%);
  clip-path: circle(50.0% at 50% 50%);
}

/* profile picture equipment */
////////////////////////
.image_equipment {
  width: 60px;
  height: 60px;
}

/* profile picture equipment kind */
////////////////////////
.form_image {
  width: 60px;
  height: 60px;
}

.logo:hover {
  cursor: pointer;
}
//End pictures

//Search map right side
.search-map-wrapper {
  padding: 0;
  min-height: calc(100% - 12px);
  width: calc(100% - 15px);
  position: absolute;
  left: 92.3%;
  top: 0;
  //z-index: 100;
  transition: 1s ease-in-out;
}

.search-maparea {
  width: 100%;
  height: 100%;
  position: absolute;
}

.search-maparea .card {
  background: none !important;
  border: none !important;
  margin: 0 !important;
}

.search-mapshow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  left: -15px;
  border-radius: 100%;
  background-image: url("../assets/img/mapswitch.png");
  background-size: cover;
  border: 0px;
  cursor: pointer;
}

.search-mapshow:focus {
  outline: none;
}

.search-map-wrapper.search-shown {
  left: 15px;
  display: block;
}
//End

//Ribbon inventory
.label-ribbon {
  font-size: 14px;
  margin-bottom: 0px;
  display: inline-block;
  padding: 7px 12px 7px 15px;
}

.component-ribbon {
  float: left;
  display: inline-flex;
  align-items: center;
}

.filter-ribbon {
  height: 38px;
  width: 125px;
  border: none;
  border-bottom: 1px solid ;
  padding: 0;
  display: inline-block;
  outline: none;
  font-size: 11px;
  appearance: none;
}

.input-ribbon {
  background: none;
  height: 38px;
  width: 125px;
  border: none;
  border-bottom: 1px solid;
  padding: 0;
  display: inline-block;
  outline: none;
  font-size: 11px;
  appearance: none;
}

.row-ribbon-top {
  align-items: center;
  min-height: 43px;
  margin: 0 auto;
}

.row-ribbon-bottom {
  align-items: center;
  min-height: 40px;
}

.filter-view-ribbon {
  height: 30px;
  width: 200px;
  border: none;
  display: inline-block;
  outline: none;
  font-size: 13px;
  margin: 0px 0px 0px 15px;
  appearance: none;
}
//End ribbon inventory

// Home + plan page
.back-image {
  width: 100%;
}

.top-row-home {
  position: absolute;
  top: 0;
  width: inherit;
}

.page-wrapper-home {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 72px);
}

.wrapper-home {
  width: 100%;
  overflow-x: hidden;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  flex-wrap: wrap;
}

.page-wrapper-home .row.border-bottom {
  margin: 0;
  max-height: 70px;
  overflow: hidden;
  box-shadow: 0px 3px 10px;
  margin-left: 235px;
}

.row-home {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.col-img-home {
  position: relative;
  display: table;
}

.widget-txt-home {
  display: block;
  height: 30%;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 4px solid;
  text-align: center;
  padding-bottom: 4%;
  padding-top: 6%;
  transition: 0.5s;
}

.widget-txt-floors {
  display: block;
  height: 30%;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  padding-bottom: 4%;
  padding-top: 6%;
  transition: 0.5s;
}

.widget-home {
  margin: 15px;
  transition: 0.5s;
  min-width: 220px;
  box-shadow: 0px 3px 6px;
}

.widget-home:hover {
  cursor: pointer;
  margin: 0;
}

.widget-icon-home {
  width: 300px;
  height: 200px;
  transition: 0.5s;
}

.widget-home:hover .widget-txt-home,
.widget-home:hover .widget-txt-floors {
  font-size: 18px;
}

.widget-home:hover .widget-icon-home {
  width: 330px;
  height: 220px;
  transition: 0.5s;
}

.wrapper-building {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  min-height: calc(100% - 18px);
}
//End home + plan page

//Sensor items
.id-title {
  display: flex;
  padding: 20px 15px 0px 15px;
  margin: 0;
  font-weight: bold;
  font-size: 16px;
}

.touch-msg {
  font-size: 26px;
  margin: 0px 14px;
}

.id-row {
  margin: 0;
  height: 100%;
  padding: 0px 15px;
}
//End sensor items

//Alert comments
.comment {
  padding: 1px;
}

.comment-meta {
  display: flex;
  width: 40%;
  align-items: center;
  justify-content: flex-start;
}

.comment-content {
  padding: 5px;
  border: 1px solid;
  border-radius: 5px;
  margin: 0 5px 5px 5px;
}

.comment-author {
  padding: 5px;
}
.comment-date {
  padding: 5px;
}
//End alert comments