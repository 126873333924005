.plan-group {
  width: auto;
  margin: 0px;
  line-height: 14px;
  font-size: 12px;
  font-weight: 900;
  padding: 2px 4px;
  text-align: center;
  border-radius: 8px;
  background: transparent;
  color: black;
}

.plan-list-title {
  font-weight: bold;
  padding: 8px;
}

.plan-list {
  border: solid 1px;
  font-family: Consolas;
  width: 320px;
  text-align: left;
  max-height: 320px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 16px;
}

.map-image {
  height: 30px;
  width: 30px;
}

.map-image-lite {
  height: 12px;
  width: 12px;
}

.map-image-circle {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: transparent;
  font-size: 25px;
  opacity: 0.8;
}

/**************************************/
/* Maybe not use */
/**************************************/
.plan-group-selected {
  border: 2px solid;
}

//Used in floor plan tooltip
.tooltip.show span div,
.tooltip.show span div b,
.plan-list span div,
.plan-list span div b {
  font-family: Consolas;
  font-size: 10px;
}

.tooltip.show {
  border: solid 1px;
  border-radius: 4px;
  padding: 8px 8px 8px 0px;
}

.tooltip.show,
.tooltip-inner,
.tooltip-inner div,
.tooltip .tooltip-inner {
  opacity: 1.0;
  overflow-x: hidden;
  text-align: left;
  // font-sie: 13px;
  width: 100%px;
  max-width: 100%;
}

.tooltip-inner div b {
  font-family: Consolas;
  // font-sie: 13px;
}