/* Mat table */
.mat-header-cell {
    overflow: visible;
    z-index: 10;

    .form-control {
        overflow: visible;
    }
} 

.mat-sort-header-content {
    flex-direction: column;
}

.mat-sort-header-container .form-control:focus {
    box-shadow: none;
}

.mat-sort-header-button:focus {
    outline: none;
}

th.mat-header-cell {
    vertical-align: bottom;
}

.mat-sort-header-button {
    display: block !important;
}
  
.mat-sort-header-button div {
    display: block;
}

.mat-sort-header-button div {
    margin-right: 10px;
    display: inline-block;
    color: #3f3f3f;
    font-size: 12px;
  }
  
.mat-sort-header-button input,
.mat-sort-header-button select {
    bottom: 0px;
    display: block;
}

.mat-menu-panel {
    min-width: 50px !important;
    max-width: 200px !important;
}

.mat-menu-item {
    padding: 0 !important;
}

.mat-header-row-class {
    background: #F2F7FD;
}
  
.mat-header-row-class .mat-sort-header-button div {
    color: #676a6c !important;
    font-size: 15px;
    width: 30%;
    text-align: left;
    display: block;
}

tr.mat-header-row {
    height: 36px !important;
}

.mat-header-top-row {
    height: 50px !important;
    background: #f2f7fd;
}
  
.mat-header-top-row .mat-sort-header-button div {
    color: #676a6c !important;
    font-size: 15px;
    width: 30%;
    text-align: left;
    height: 30px;
    line-height: 30px;
}

.mat-header-top-row tbody {
    display: none;
}

.mat-table-s-row td {
    border-bottom-color: #ffffff0d;
}

.mat-header-cell {
    padding-right: 8px !important;
}

.mat-cell,
.mat-footer-cell {
  font-size: 13px;
}

th.mat-header-cell,
.mat-sort-header-button {
  letter-spacing: 1px !important;
  font-size: 14px !important;
}

td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type,
th.mat-header-cell:first-of-type {
  padding-left: 18px !important;
}

td.mat-cell:last-of-type,
td.mat-footer-cell:last-of-type,
th.mat-header-cell:last-of-type {
  padding-right: 0px !important;
}

.mat-cell,
.mat-footer-cell {
  font-size: 13px;
}

td.mat-cell,
td.mat-footer-cell,
th.mat-header-cell {
  border: none !important;
}

.mat-row:nth-child(even) {
    background-color: #f5f8fa !important;
}
  
.mat-row:nth-child(odd) {
    background-color: #ffffff !important;
}

tr.mat-footer-row,
tr.mat-row {
  height: 32px !important;
}

.mat-form-field-underline {
    position: initial !important;
}

button.btn.no-margin-button {
    margin-left: 0px;
    margin-right: 0px;
}

// Datetime placeholder "jj/mm/aaaa" in gray
input[type="date"]:invalid {
    color: lightgray;
}

tr.mat-row td.mat-cell {
    padding: 0 4px;
}

.lang-select span {
    color: #838a8d;
    border: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
  
.lang-menu {
    cursor: pointer;
    margin-right: 25px;
}
  
.mat-elevation-z8 {
    box-shadow: none;
}

.mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-page-size {
    align-items: center;
    margin: 0;
    height: 50px;
}
  
.mat-paginator .mat-paginator-outer-container .mat-paginator-container .mat-paginator-range-actions {
    height: 50px;
}

.mat-paginator-navigation-first:disabled path,
.mat-paginator-navigation-previous:disabled path,
.mat-paginator-navigation-next:disabled path,
.mat-paginator-navigation-last:disabled path {
  color: grey;
}

app-gateway-list .mat-sort-header-button {
    display: block;
}
  
app-gateway-list .mat-sort-header-button div {
    font-size: 13px;
    float: left;
    margin: 0;
}

.mat-dialog-container {
    padding: 0px !important;
}