/*// screens
$mobile: 'screen and (max-width: 767px)';
$tablet: 'screen and (min-width: 768px) and (max-width: 1023px)';
$desktop: 'screen and (min-width: 1024px) and (max-width: 1559px)';
$large_desktop: 'screen and (min-width: 1560px)';*/

// colors
$app_white: white;
$app_black: black;
$app_blue: #3f51b5;
$app_red: red;
$app_dark_red: darkred;
$app_grey: #b1b1b1;
$app_light_grey: whitesmoke;
$app_grey_disabled: #fafafa;
$app_grey_border: #dfdddd;
$app_grey_input: #f8f9ff;




$app_backcolor: white;
$app_forecolor: #4680D6;
$app_label_forecolor: #a1a1a1;

$app_border: #dfdddd;
$app_selected: #CCDCF3;

$app_back_button: #6F9BE0;
$app_border_button: #4680D6;
$app_selected_button: #265DAA;
$app_fore_button: #fafafa;

$col-fore-inf: #3f51b5; // dark blue
$col-fore-ok: #009900; // dark green
$col-fore-wrn: #BF5900; //#A85900; //#BB6600;// #AA6600; // dark orange
$col-fore-err: #AA0000; // dark red
$col-fore-exc: white;


$col-back-inf: #def; // pastel blue
$col-back-ok: #efe; // pastel green
$col-back-wrn: #ffe; // pastel yellow
$col-back-err: #fee; // pastel red
$col-back-exc: red;
