@import "wt-skin-new";

.table {
  min-width: 400px;
}
/*
.mat-table-s-row {
  width: 100%;
}

.table-column {
  margin: 20px;
}

td, th {
  max-width: 230px;
  word-wrap: break-word;
}

.table.cell-with-link {
  cursor: pointer;
}

th {
  background-color: $app_light_grey;
  padding: 20%;
}

.th:last-of-type, .td:last-of-type {
  border-left: solid 1px $app_grey_border;
}

.table-responsive {
  border: solid 1px $app_grey_border;
}

.table-header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.table-header input,
.table-header select {
  border: solid 1px $app_grey_border;
}

.table-header-label {
  text-align: center;
}

td {
  white-space: nowrap;
  overflow: hidden;
}

.mat-calendar-table-header th {
  background-color: $app_white;
}
*/
/*
.left {
  margin-left: 6px;
}

.right {
  float: right;
  margin-right: 6px;
}
*/

/*
.image-circle {
  float: left;
  border-radius: 50%;
  margin: 5px 2px 6px 8px;
  width: 8px;
  height: 8px;
  background: transparent;
  opacity: 1;
}*/

.table.compact {
  border: 2px solid silver;
  background: white;
}

.table.compact th {
  border: 1px solid gainsboro;
  background: whitesmoke;
}

/*
.table.compact tr.sensor th {
  background: #F3F7FB;
}
*/
.table.compact tr {
  height: 22px;
}

.table.compact td {
  border: 1px solid gainsboro;
}

.table.compact th,
.table.compact td {
  height: 22px;
  padding: 0px 4px;
  font-size: 12px;
  line-height: 10px;
  vertical-align: middle;
}

.table.compact tr.selected {
  background: $app_selected;
}

div.text-info,
.table.compact td.text-info {
  color: darkblue !important;
  background: aliceblue;
}

div.text-success,
.table.compact td.text-success {
  color: green;
  background: honeydew;
}

div.text-running,
.table.compact td.text-running {
  color: DarkOliveGreen;
  background: beige;
}

div.text-warning,
.table.compact td.text-warning {
  color: #8a6d3b !important;
  background: lemonchiffon;
}

div.text-error,
.table.compact td.text-error {
  color: darkred;
  background: mistyrose;
}