@media screen and (min-width: 987px) {
  .quick-search {
    width: 50%;
  }
}

@media screen and (min-width: 768px) and (max-width: 986px) {
  .quick-search {
    width: 70%;
  }
}

@media screen and (min-width: 576) and (max-width: 767px) {
  .quick-search {
    width: 80%;
  }
}

@media screen and (max-width: 575px) {
  .xs-quick-search-row {
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica/Helvetica.ttf');
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: url('../assets/fonts/Helvetica/Helvetica-Bold.ttf');
}

$main_font: "Helvetica", sans-serif;
$main_font_size: 13px;
$main_font_weight: 400;

//Common
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
}

body {
  // to fix the pull down behaviour of chrome with android device, which refresh when scrolling to top
  overscroll-behavior: contain;
  margin: 0;
  line-height: 1.5;
  text-align: left;
}

* {
  font-family: $main_font;
  font-size: $main_font_size;
  font-weight: $main_font_weight;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  color: inherit;
}

label {
  font-size: 11px;
  margin: 0px;
}

button:focus {
  outline: none;
}

p {
  margin-bottom: 0px;
}

table {
  width: 100%;
}

//End common

//Settings page
.msg-little {
  padding-top: 6px;
  font-size: 10px;
}

.indent {
  margin-left: 8px;
}

.indent input {
  float: left;
}

.default {
  float: left;
  margin: 0px 8px;
  padding-top: 6px;
  font-size: 11px;
}

//End settings page

//Exports page
.small-text {
  font-size: 10px;
  font-weight: 400;
}

//End exports page

//Ribbon
.page-title {
  width: 100%;
  text-align: center;
  position: fixed;
  margin: 0px;
  z-index: 100;
}

.position-initial {
  position: initial !important;
}

.ribbon-message {
  border: solid 1px;
  font-size: 10px;
  height: 20px;
  padding: 0px 4px;
  margin-top: 2px;
  margin-bottom: 0px;
  padding: 2px 8px;
}

.historic-info {
  border: none;
  margin: 0px 16px;
  display: flex;
  align-items: center;
}
//End ribbon

//Button
.btn {
  font-size: 12px;
  height: 26px;
  padding: 0px 8px;
  margin: 0px 4px;
  line-height: 24px;
}

.btn.btn-transparent {
  margin: 0px;
}

.btn.btn-small {
  height: 22px;
  width: 22px;
  padding: 0px 4px;
  margin: -6px 2px 0px 2px;
}

.btn-rounded {
  font: normal normal bold 14px Helvetica;
  border-radius: 50px;
  letter-spacing: 1px;
  height: 38px;
  width: 130px;
  border: none;
  transition: 0.2s;
  cursor: pointer;
  padding: 0px; //inherit;
  margin: 4px;
}

.btn-rounded.btn-close {
  position: relative;
  right: 0;
}

.btn-rounded.btn-close:hover {
  opacity: 0.8;
}

.btn-dialog {
  font: normal normal bold 14px Helvetica;
  border-radius: 4px;
  letter-spacing: 1px;
  height: 32px;
  width: 120px;
  border: 1px solid;
  transition: 0.2s;
  cursor: pointer;
  padding: 0px;
  margin: 4px;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: none;
}

//End button

//Input
input[type='date'],
input[type='time'] {
  position: relative;
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-clear-button,
input[type="time"]::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
  z-index: 1;
}

input[type='time']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 2px;
  padding: 2px 2px;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=date-time].form-control {
  padding: 4px;
}

input[type=checkbox].form-control {
  width: 20px;
  height: 20px;
}

//End input

//Form
.form-body {
  margin: 20px;
}

.form-group {
  margin-bottom: 8px;
}

.form-group-margin-bottom {
  margin-bottom: 35px;
}

app-rule-filters .form-group,
app-rule-parameters .form-group {
  margin-bottom: 0px;
}
//End form

//Search
.search {
  padding-bottom: 2px;
  text-align: right;
}

//End search

//Visibility
.hidden {
  display: none;
}

.shown {
  display: block;
}

.wrapper-reduced {
  width: 90%;
}

/*
  this allow images to be calculated, but use the space of the item
  so must be the lat item of the page
*/
.visible-hidden {
  visibility: hidden;
}

/*
  this allow images to be calculated, but use the space of the item
  so must be the lat item of the page
*/
.visible-shown {
  visibility: visible;
}
//End visibility

//Select
select {
  background-color: transparent !important;
  appearance: none;
  background: url('../assets/img/chevron-small-down.png') no-repeat right transparent;
  background-size: 14px;
  padding-right: 18px !important;
}

.select-checkbox option::before {
  content: "\2610";
  font-size: 20px;
  margin-top: -6px;
  width: 1.1em;
  text-align: left;
  vertical-align: middle;
  display: inline-block;
}

.select-checkbox option:checked::before {
  content: "\2611";
}

.select-checkbox {
  overflow: auto;
  border: none !important;
  background: none !important;
}

// to disalble list auto scroll under chrome (doesn't work)
.select-checkbox,
.select-checkbox option {
  overflow-anchor: none !important;
  scroll-snap-stop: normal !important;
  overscroll-behavior: unset !important;
  scroll-behavior: unset !important;
  height: 20px;
}

.multiselect {
  display: inline-grid;
}

.selectBox {
  position: relative;
}

.selectBox select {
  width: 100%;
  border: none;
  border-bottom: 1px solid;
  height: 30px;
  appearance: none;
}

.overSelect {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.form-control {
  height: 28px;
  border: none;
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid;
  padding: 0;
}

.form-control:disabled,
.form-control[readonly] {
  opacity: 1;
}

.search-selectequip-single {
  height: 30px;
  width: calc(100% + 20px);
  appearance: none;
  border: none;
  border-bottom: 1px solid;
}

.search-selectequip-single:focus {
  outline: none;
}
//End select

//Detail view cards
.card-container {
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

.card-back {
  padding: 10px 5px 6px 5px;
}

.card-core {
  display: inline-block;
  border: 1px solid;
  border-radius: 0px;
  min-height: 156px;
  max-height: 156px;
  overflow-y: auto;
  padding-left: 4px;
}

.card-label {
  width: 80px;
  font-weight: bolder;
  font-size: 11px;
}

.card-head {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 26%;
}

.card-icon {
  margin-top: 2px;
  font-size: 30px;
}
// End detail view cards

// Breadcrumb
.bread-crumb {
  position: fixed;
  z-index: 100;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  height: 40px;
}

.btn-breadcrumb {
  align-items: center;
}

.link-breadcrumb {
  font-size: 13px;
  padding: 6px 0px;
  margin-right: 20px;
}

.btn-breadcrumb img {
  width: 12px;
}

.arrow-back {
  margin: 0px 15px;
  cursor: pointer;
}

/** Default button **/
.btn-breadcrumb .btn {
  line-height: 14px;
}

.btn-breadcrumb i {
  margin-right: 10px;
}

.breadcrumb-small .bread-crumb {
  height: 36px;
}

.breadcrumb-small .btn-breadcrumb .arrow-back {
  margin: 0px 8px 0px 8px;
}

.breadcrumb-small .link-breadcrumb {
  margin-right: 12px;
  padding: 6px 0px;
}

.btn {
  font-size: 12px;
  margin-left: 8px;
}
//End breadcrumb

//Sidenav
.mtr-span .sidenav-toggle-button {
  display: inline-table;
  vertical-align: middle;
  margin-left: 26px;
}

.sidenav {
  height: calc(100% - 94px);
  width: 220px;
  position: fixed;
  z-index: 1;
  left: 0;
  overflow-x: hidden;
  margin-top: 70px;
}

.sidenav a,
.dropdown-btn {
  text-decoration: none;
  display: block;
  border: none;
  width: 100%;
  height: 45px;
  text-align: left;
  cursor: pointer;
  font-size: 13px;
  font-weight: normal;
}

.sidenav .navmenu-icon {
  position: absolute;
  right: 4px;
}

.map-btn {
  padding: 16px 0px 0px 4px;
}

.sidenav .fa {
  font-size: 150%;
  margin-right: 4px;
}

.menu-text {
  font-size: 13px;
  max-width: 100%;
  font-weight: 600;
  margin-left: 5px;
  letter-spacing: 0.04em;
}

.dropdown-container a {
  padding: 7px 0px;
}

.dropdown-container img {
  height: 15px;
  margin: 0px 15px 0px 10px;
}
//End sidenav

//Wrapper
.page-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  //overflow-x: hidden;
  //overflow-y: auto;
  height: calc(100% - 72px);
}

.wrapper-content {
  margin: 18px;
  padding: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
//End wrapper

//Export panel
.col-export {
  display: inline-block;
  vertical-align: top;
  padding-bottom: 40px;
}

.export-panel-header {
  padding: 0px 20px;
  height: 42px;
  display: flex;
  align-items: center;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
}

.export-panel-body {
  min-height: 200px;
  padding: 20px 20px 10px 20px;
}

.export-panel-widget {
  width: 100%;
  padding: 0;
  margin: 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

app-export-panel .form-group {
  align-items: center;
  margin-bottom: 12px;
}
//End export panel

//Misc
.loading-txt {
  text-align: center;
  font-size: 14px;
  font-family: 'Helvetica-Bold';
  margin: 10px;
}

.image-circle {
  float: left;
  border-radius: 50%;
  margin: 6px 8px;
  width: 8px;
  height: 8px;
  background: transparent;
  opacity: 1;
}

.img-dl {
  filter: invert(1);
  width: 20px;
}

app-info-ribbon input[type=checkbox].form-control {
  width: 16px;
}

.row-center {
  width: 100%;
  display: flex;
  justify-content: center;
}
//End misc