@import "wt-skin-new";


button,
table.table > tr > td > button {
  display: inline;
  /*align-items: flex-start;*/
  line-height: 22px;
  height: 28px;
  width: 140px;
  margin: 4px;
  padding: 2px 4px;
  background: $app_back_button;
  color: $app_fore_button;
  border-radius: 4px;
  border-color: $app_border_button;
}

button:not(.display):hover {
  background: $app_border_button;
}

button:not(.display):active {
  /*background: $app_fore_button;*/
  color: $app_back_button;
}

button.option,
button.display {
  display: inline-flex;
  align-items: flex-start;
  margin: 4px;
  width: 32px;
  padding: 2px 0px 0px 2px;
  border-radius: 0px;
}
button.display {
  width: 26px;
  height: 26px;
}

button.option.wide {
  width: 120px;
}

button.option.text {
  text-align: left;
  padding: 2px 4px;
  width: 160px;
}
/*
button mat-icon.mat-icon,
td mat-icon.mat-icon {
  padding: 0px;
}
*/
button.option mat-icon.mat-icon {
  margin-top: 1px;
  margin-right: 4px;
  margin-left: 3px;
}

button.selected,
button.option.selected {
  background: $app_selected_button;
}

button:disabled,
button:disabled:hover,
table.table > tr > td > button:disabled,
table.table > tr > td > button:disabled:hover {
  background: $app_grey_border;
  color: $app_grey;
  border-color: $app_grey;
}


button.display {
  margin: 4px 0px;
  background: black;
  border-color: black;
  color: DarkOliveGreen;
}


button.display.selected {
  color: lime;
}



div.pane {
  padding: 4px 8px;
  background: $app_backcolor;
  border: 2px solid silver;
  /*border-top: 1px solid $app_border;*/
}


mat-icon.mat-icon {
  background: transparent;
  color: inherit;
  padding: 0px;
  font-size: 20px;
  height: 20px;
  width: 20px;
}

mat-icon.mat-icon.red-icon {
  color: $app_dark_red;
}

mat-icon.mat-icon.blue-icon {
  color: $app_blue;
}

mat-icon.mat-icon.gray-icon {
  color: $app_grey;
}



/*
input.invalid,
select.invalid {
  border: solid red 2px;
}

.select {
  min-width: 80px;
}

input,
select {
  height: 26px;
}

textarea {
  height: 95px;
}

input:disabled,
select:disabled,
textarea:disabled {
  border: solid 1px $app_grey_border;
  background-color: transparent;
}
*/

div.editor {
  display: flex;
  margin: 4px 4px 4px 4px;
  vertical-align: middle;
}

div.editor label {
  margin-top: 3px;
  width: 160px;
  display: flex;
  vertical-align: central;
  color: $app_label_forecolor;
}

div.editor input,
div.editor select,
div.editor textarea {
  width: 100%;
}



.msg {
  border: solid 1px white;
  color: gray;
  background: gainsboro;
  font-weight: bold;
  padding: 2px 6px;
  margin: 6px 6px;
  /*width: fit-content;*/
}

.edit.inf,
.msg.inf {
  background: $col-back-inf;
  color: $col-fore-inf;
  border-color: $col-fore-inf;
}

.edit.ok,
.msg.ok {
  background: $col-back-ok;
  color: $col-fore-ok;
  border-color: $col-fore-ok;
}

.edit.wrn,
.msg.wrn {
  background: $col-back-wrn;
  color: $col-fore-wrn;
  border-color: $col-fore-wrn;
}

.edit.err,
.msg.err {
  background: $col-back-err;
  color: $col-fore-err;
  border-color: $col-fore-err;
}

.edit.exc,
.msg.exc {
  background: $col-back-exc;
  color: $col-fore-exc;
  border-color: $col-fore-exc;
}