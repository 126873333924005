// this manage colors
//////////////////////////////

$back_color: #FFFFFF; // white
$back_color_main: #F7F9FC; // soft gray
$fore_color: #2D6C9B; //#1A88B9; // blue
$fore_color_lite: #CBE0F0; // #CFE2F1; // ligth blue

$fore_color_gray: #949596; // gray
$fore_color_darkgray: #505152; //dark gray
$back_color_gray: #d4d5d6; // gray
$back_color_darkgray: #B2C1C7;

$fore_color_error: #AA0000; // dark red
$back_color_error: #fdd; // pastel red
$fore_color_warn: #AA7700; // dark orange
$back_color_warn: #ffd; // pastel yellow
$fore_color_info: #0000AA; // dark blue
$back_color_info: #def; // pastel blue
$fore_color_ok: #00AA00; // dark green
$back_color_ok: #dfd; // pastel green

$fore_color_ok: #00AA00; // dark green

$back_color_highlighted: #EEEE00; // dark green

$legend_color: #9999AA; // cyan

$back_color_invert: $fore_color;
$back_color_invert_lite: $fore_color_lite;
$fore_color_invert: $back_color;
$fore_color_hover: $fore_color;

$header_table_color: #F2F7FD;


$dashboard_main_back_color: #2B526F; // dark
$dashboard_card_back_color: #284C66; // dark 2
$dashboard_text_color: white; // white

/* Common */
////////////

* {
  color: $fore_color;
}

body {
  background-color: $back_color_main;
}

.bread-crumb {
  background-color: $back_color_main;
}

.link-breadcrumb:hover {
  color: $fore_color;
}

.link-breadcrumb {
  color: $fore_color_darkgray;
}

.fa,
.fas {
  color: $fore_color;
}

.page-title {
  background: $back_color_main;
}

.btn-rounded {
  background-color: #03B4F6;
  color: $dashboard_text_color;
}

.btn-rounded:hover {
  background-color: rgba(3, 180, 246, 0.8);
  border-color: rgba(3, 180, 246, 0.8);
}

.work-border,
.panel-header {
  color: $fore_color;
  border-color: $fore_color;
}

.title-bar {
  background-color: $back_color_invert;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.panel-header {
  background-color: $back_color_invert_lite;
  color: $fore_color;
  border-color: $fore_color;
}

.dialog-header {
  background: $back_color;
}

.btn.btn-small,
.btn.btn-small i,
.btn.btn-small:active,
.btn.btn-small:focus {
  background-color: transparent;
  //background-color: $fore_color_lite;
  color: $fore_color;
  //border-color: $fore_color;
  border:none;
}

.btn.btn-small:hover {
  background-color: $back_color_invert;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn.btn-small:hover .fas,
.btn-small:hover .fa,
.btn.btn-small:hover span,
.btn.btn-small:hover div {
  color: $fore_color_invert;
}

.btn-disabled,
.btn-disabled:active,
.btn-disabled:focus,
.btn-disabled div,
.btn-disabled i {
  background-color: $back_color_gray;
  color: $fore_color_gray;
  border-color: $fore_color_gray;
}

.btn-disabled:hover .fas,
.btn-disabled:hover .fa,
.btn-disabled:hover span,
.btn-disabled:hover div,
.btn-disabled:hover {
  background-color: $fore_color_gray;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-transparent,
.btn-transparent:active,
.btn-transparent:focus,
.btn-transparent div,
.btn-transparent i {
  background-color: transparent;
  color: $fore_color;
  border: none;
}

.btn-transparent:hover .fas,
.btn-transparent:hover .fa,
.btn-transparent:hover span,
.btn-transparent:hover div,
.btn-transparent:hover {
  background-color: transparent;
  color: $fore_color_gray;
  border: none;
}

.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary div,
.btn-primary i {
  background-color: $back_color_invert_lite;
  color: $fore_color;
  border-color: $fore_color;
}

.btn-primary:hover .fas,
.btn-primary:hover .fa,
.btn-primary:hover span,
.btn-primary:hover div,
.btn-primary:hover {
  background-color: $back_color_invert;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-primary:disabled,
.btn-primary:disabled div,
.btn-primary:disabled i,
.btn-primary:disabled:hover i,
.btn-error:disabled,
.btn-error:disabled div,
.btn-error:disabled i,
.btn-error:disabled:hover i,
.panel-header-disabled,
.panel-header-disabled div,
.panel-header-disabled div i {
  background-color: $back_color_gray;
  color: $fore_color_gray;
  border-color: $fore_color_gray;
}

button.btn.disabled,
button.btn:disabled {
  opacity: 0.50;
  border-color: $fore_color_gray;
  color: $fore_color_gray;
}


button.btn.disabled:hover,
button.btn:disabled:hover {
  opacity: 0.20;
}

.back_lite {
  background: $back_color_invert;
  color: $fore_color_invert;
}


.msg-error {
  background-color: $back_color_error;
  color: $fore_color_error;
  border-color: $fore_color_error;
}

.msg-warn {
  background-color: $back_color_warn;
  color: $fore_color_warn;
  border-color: $fore_color_warn;
}

.msg-info {
  background-color: $back_color_info;
  color: $fore_color_info;
  border-color: $fore_color_info;
}

.msg-ok {
  background-color: $back_color_ok;
  color: $fore_color_ok;
  border-color: $fore_color_ok;
}

// bton inv

.btn-inv-error,
.btn-inv-error:active,
.btn-inv-error:focus,
.btn-inv-error div,
.btn-inv-error i {
  background-color: $fore_color_error;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-inv-error:hover .fas,
.btn-inv-error:hover .fa,
.btn-inv-error:hover span,
.btn-inv-error:hover div,
.btn-inv-error:hover {
  background-color: $back_color_error;
  color: $fore_color_error;
  border-color: $fore_color_error;
}

.btn-inv-warn,
.btn-inv-warn:active,
.btn-inv-warn:focus,
.btn-inv-warn div,
.btn-inv-warn i {
  background-color: $fore_color_warn;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-inv-warn:hover .fas,
.btn-inv-warn:hover .fa,
.btn-inv-warn:hover span,
.btn-inv-warn:hover div,
.btn-inv-warn:hover {
  background-color: $back_color_warn;
  color: $fore_color_warn;
  border-color: $fore_color_warn;
}

.btn-inv-info,
.btn-inv-info:active,
.btn-inv-info:focus,
.btn-inv-info div,
.btn-inv-info i,
.btn-inv-info:disabled,
.btn-inv-info:disabled div,
.btn-inv-info:disabled i,
.btn-inv-info:disabled:hover i {
  background-color: $fore_color_info;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-inv-info:hover .fas,
.btn-inv-info:hover .fa,
.btn-inv-info:hover span,
.btn-inv-info:hover div,
.btn-inv-info:hover {
  background-color: $back_color_info;
  color: $fore_color_info;
  border-color: $fore_color_info;
}

.btn-inv-ok,
.btn-inv-ok:active,
.btn-inv-ok:focus,
.btn-inv-ok div,
.btn-inv-ok i {
  background-color: $fore_color_ok;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-inv-ok:hover .fas,
.btn-inv-ok:hover .fa,
.btn-inv-ok:hover span,
.btn-inv-ok:hover div,
.btn-inv-ok:hover {
  background-color: $back_color_ok;
  color: $fore_color_ok;
  border-color: $fore_color_ok;
}

// btn
.btn-rounded:hover {
  background-color: rgba(3, 180, 246, 0.8);
  border-color: rgba(3, 180, 246, 0.8);
}

.btn-rounded:disabled {
  background-color: #BBB;
  border-color: #BBB;
  color: #EEE;
}

.btn-rounded.btn-close,
.btn-rounded.btn-close:hover {
  background-color: #B2B1C7;
}


.btn-error,
.btn-error:active,
.btn-error:focus,
.btn-error div,
.btn-error i {
  background-color: $back_color_error;
  color: $fore_color_error;
  border-color: $fore_color_error;
}

.btn-error:hover .fas,
.btn-error:hover .fa,
.btn-error:hover span,
.btn-error:hover div,
.btn-error:hover {
  background-color: $fore_color_error;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-warn,
.btn-warn:active,
.btn-warn:focus,
.btn-warn div,
.btn-warn i {
  background-color: $back_color_warn;
  color: $fore_color_warn;
  border-color: $fore_color_warn;
}

.btn-warn:hover .fas,
.btn-warn:hover .fa,
.btn-warn:hover span,
.btn-warn:hover div,
.btn-warn:hover {
  background-color: $fore_color_warn;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-info,
.btn-info:active,
.btn-info:focus,
.btn-info div,
.btn-info i,
.btn-info:disabled,
.btn-info:disabled div,
.btn-info:disabled i,
.btn-info:disabled:hover i {
  background-color: $back_color_info;
  color: $fore_color_info;
  border-color: $fore_color_info;
}

.btn-info:hover .fas,
.btn-info:hover .fa,
.btn-info:hover span,
.btn-info:hover div,
.btn-info:hover {
  background-color: $fore_color_info;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-ok,
.btn-ok:active,
.btn-ok:focus,
.btn-ok div,
.btn-ok i {
  background-color: $back_color_ok;
  color: $fore_color_ok;
  border-color: $fore_color_ok;
}

.btn-ok:hover .fas,
.btn-ok:hover .fa,
.btn-ok:hover span,
.btn-ok:hover div,
.btn-ok:hover {
  background-color: $fore_color_ok;
  color: $fore_color_invert;
  border-color: $fore_color_invert;
}

.btn-breadcrumb .disabled {
  color: $fore_color_darkgray;
}


// Filters
///////////////////
// .filter-red {
//   webkit-filter: saturate(2000%) hue-rotate(140deg) brightness(80%);
//   filter: saturate(2000%) hue-rotate(140deg) brightness(80%);
// }

// .filter-orange {
//   webkit-filter: saturate(2000%) hue-rotate(180deg) brightness(100%);
//   filter: saturate(2000%) hue-rotate(180deg) brightness(100%);
// }

// .filter-green {
//   webkit-filter: saturate(2000%) hue-rotate(240deg) brightness(100%);
//   filter: saturate(2000%) hue-rotate(240deg) brightness(100%);
// }

// .filter-blue {
//   webkit-filter: saturate(2000%) hue-rotate(240deg) brightness(100%);
//   filter: saturate(2000%) hue-rotate(240deg) brightness(100%);
// }

// .filter-gray {
//   webkit-filter: saturate(0%) hue-rotate(140deg) brightness(100%);
//   filter: saturate(0%) hue-rotate(140deg) brightness(100%);
// }

// Messages
///////////////////
.msg-error,
.form-control.is-invalid {
  color: $fore_color_error;
}

.msg-warn {
  color: $fore_color_warn;
}

.default,
.msg-legend {
  color: $fore_color_gray;
}
/*
.msg-ok {
    color: $fore_color_ok;
}*/

.legend {
  color: $legend_color;
}

mark {
  background-color: $back_color;
  color: $legend_color;
  font-weight: bold;
}

app-dashboard .title-bar
.mat-toolbar-row
.mtr-span
.mat-icon-button
.mat-button-wrapper
.menu-icon {
  color: white;
}

// Workflow
//////////////
.work-button {
  background-color: $back_color_invert;
  color: $fore_color_invert;
  border-color: $fore_color_lite;
  border-width: 1px;
  border-style: solid;
}

.work-button:hover {
  background-color: $back_color;
  color: $fore_color;
}

/* On mouse-over */

.mat-menu-item {
  outline-color: rgba(0, 0, 0, 0);
}

.dash-card-icon .fa,
.dash-card-icon .fas {
  color: $fore_color_invert !important;
}

.work-container-logo:hover, .work-container-logo:hover i {
  color: $fore_color_hover;
}
/*
.work-container-logo:hover .work-logo {
  border-color: $fore_color_hover;
  color: $fore_color_hover;
}
*/
.tooltip.show {
  border-color: $fore_color_gray;
}

.tooltip.show,
.tooltip-inner,
.tooltip-inner div,
.tooltip .tooltip-inner {
  background-color: $back_color_main;
  color: $fore_color;
}

// .tooltip .arrow::before {
//   border-top-color: $back_color_invert_lite;
// }

.selected-row,
.selected-row td {
  color: $fore_color_invert;
  background: $back_color_invert;
}


.highlighted-row td:not(.not-highlighted-row) {
  color: $fore_color;
  background: $back_color_invert_lite;
}


.highlighted-row-light td:not(.not-highlighted-row) {
  color: $fore_color;
  background: $back_color_warn;
  border-top: 1px solid $fore_color_error;
  border-bottom: 1px solid $fore_color_error;
  font-weight: bold;
}

/*
td.not-highlighted-row,
.not-highlighted-row td {
    color: $fore_color;
    background: $back_color;
}*/

.active {
  color: $fore_color;
  background: $back_color_invert_lite;
}

.inactive {
  color: $fore_color;
  // background: $back_color_invert_lite;
}

.crumb {
  border-color: $fore_color_gray;
}

.search-map-wrapper {
  background-color: $back_color;
}

.wrapper-content {
  background-color: $back_color;
 // box-shadow: rgba(0, 0, 0, 0.2);
}

.search-maparea {
  background-color: $back_color_gray;
}

//swagger

.swagger-ui .btn.cancel:hover {
  color: $fore_color_invert
}

.swagger-ui .btn.authorize:hover span {
  color: $fore_color_invert
}

.swagger-ui .btn:hover {
  background-color: $back_color_invert;
  color: $fore_color_invert;
}

.swagger-ui .opblock.opblock-post .tab-header .tab-item.active h4 span:after {
  background: $back_color_main
}

.swagger-ui .opblock-tag {
  border: 1px solid $fore_color;
  background-color: $back_color_invert_lite;
}

.swagger-ui .opblock {
  border: 1px solid $fore_color;
}

.swagger-ui .opblock.is-open .opblock-summary {
  border-bottom: 1px solid $fore_color;
}

.swagger-ui .btn {
  background-color: $back_color_invert_lite;
  color: $fore_color;
  border-color: $fore_color;
}

.swagger-ui .btn.authorize {
  color: $fore_color;
  border-color: $fore_color;
}

.swagger-ui select {
  border: 1px solid $fore_color;
}

.swagger-ui select:focus {
  border: 1px solid $fore_color;
}


.opblock-tag,
.opblock-tag * {
  //background-color: $back_color_invert_lite !important;
  color: $fore_color !important;
}

.swagger-ui .opblock-tag {
  border-color: $fore_color !important;
  //border-radius: 4px !important;
}

.opblock-body pre.microlight {
  color: $fore_color !important;
}

.swagger-ui .copy-to-clipboard {
  background: $back_color_invert;
}

.swagger-ui .info .title small {
  background: $back_color_invert;
}

.swagger-ui .download-contents {
  background: $back_color_invert;
}

.selectBox select {
  background-color: $back_color_main;
  color: $fore_color_darkgray;
}

.form-control,
.search-selectequip-single {
  border-color: #B5C0C6;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e4e7ea !important;
}

.search-selectequip-single {
  background-color: transparent;
}

.select-checkbox option::before {
  color: $fore_color_gray;
}

.select-checkbox option {
  color: $fore_color_darkgray;
}

.select-checkbox option:checked::before {
  color: $fore_color_gray;
}

.select-checkbox {
  background-color: $back_color_main;
}

.search-selectequip-single option,
.search-top label,
.search-bottom label {
  color: $fore_color_darkgray;
}

.search-selectequip-single option:hover {
  background-color: $back_color_invert;
}

.search-selectequip-single {
  color: $fore_color_darkgray;
}

input.building {
  color: $fore_color_darkgray;
}

//app-work-search .mat-table tr.mat-header-row{
//    background-color: $back_color_darkgray;
//}

// app-work-search .mat-elevation-z8
// .mat-table
// tr.mat-header-row
// .mat-header-cell
// .mat-sort-header-container,
// app-work-search .mat-elevation-z8
// .mat-table
// tr.mat-header-row
// th.mat-header-cell,
// app-events-list .mat-table
// tr.mat-header-row
// th.mat-header-cell,
// app-events-list .mat-table
// tr.mat-header-row
// th.mat-header-cell
// .mat-sort-header-container {
//     color: white;
//     font-weight: bold;
// }

.select-list-title {
  background: #EEEEEE;
}

.select-list {
  border-color: $back_color_gray;
  background: $back_color;
}

.select-card-item {
  border-color: $back_color_gray;
  background: $back_color;
}

.mat-sort-header-container,
th.mat-header-cell {
  color: $fore_color_darkgray;
  //font-weight: bold;
}

.code-error.form-control:disabled,
.code-error.form-control[readonly] {
  color: $fore_color_info;
  background-color: $back_color !important;
  border-color: $back_color_gray;
}

.page-footer,
.page-footer-right,
.page-footer-left {
  background: $back_color_main;
}

//Ribbon
.page-title {
  background: $back_color_main;
}

.historic-info {
  background: #EEEEEE;
  background-color: transparent;
  color: $fore_color_invert;
}
//End ribbon

//Ribbon inventory
.label-ribbon {
  color: $fore_color_darkgray;
}

.filter-ribbon,
.input-ribbon {
  color : $fore_color_darkgray;
  border-bottom-color: $fore_color_darkgray;
}

.row-ribbon-top,
.row-ribbon-bottom {
  background-color: $back_color_main;
}

.filter-view-ribbon,
.filter-ribbon option,
.filter-view-ribbon option {
  color: $fore_color_darkgray;
}
//End ribbon inventory

//Detail view cards
.card-container {
  background-color: $back_color;
}

.card-core {
  border-color: #ccc;
  background: #EEEEEE;
}
// End detail view cards

//Sidenav style
.sidenav, .dropdown-btn {
  background-color: $back_color;
}

.dropdown-btn:hover .menu-text,
.dropdown-btn.active .menu-text,
.children-menu-text,
.map-btn:hover span {
  color: $fore_color_gray;
}

.menu-text,
.dropdown-container a:hover .children-menu-text {
  color: $fore_color;
}

.dropdown-container a.selected .children-menu-text {
  color: $fore_color;
}

.dropdown-container a:hover img,
.dropdown-container a.selected img {
  filter: invert(66%) sepia(36%) saturate(1044%) hue-rotate(163deg) brightness(96%) contrast(80%);
}
//End sidenav style

.float-top-left-spinner {
  background-color: $back_color;
  border-color: $fore_color;
}

.rule-pane {
  border-color: $fore_color;
}

.work-pane-title {
  color: $fore_color_darkgray;
}

.work-pane-button {
  color: $fore_color_invert;
}

.dash-card-back {
  background-color: $back_color;
  border-color: $fore_color;
}

.dash-card-icon {
  background: $fore_color;
  color: $dashboard_text_color;
}

.dash-card-title,
.dash-card-text,
.dash-card-small-title {
  color: #009DA0;
}

tr.mat-header-row {
  background: $header_table_color;
}

.form-control:focus {
  background-color: transparent;
  border-color: $fore_color;
}

.dash-trend-label {
  color: $dashboard_text_color;
}

.filter {
  color: $fore_color_darkgray;
}

.filter-select {
  background-color: $header_table_color;
  color: $fore_color_darkgray;
  border-color: $fore_color_darkgray;
}
// Dashboard


.dashboard .dash-card-back,
.dashboard .dash-card-title,
.dashboard .dash-card-text {
  background-color: $dashboard_card_back_color;
  color: $dashboard_text_color;
}

.dashboard .btn-breadcrumb .btn:not(:last-child):after {
  border-left-color: $dashboard_main_back_color;
}

.dashboard .btn-breadcrumb .btn:not(:last-child):before {
  border-left-color: rgb(173, 173, 173);
}

.dashboard .btn-breadcrumb .btn {
  color: $dashboard_text_color;
}

.dashboard .page-title,
.dashboard .page-title .spinner-border,
.dashboard .page-title .loading-txt,
.dashboard .bread-crumb,
.dashboard .bread-crumb a,
.dashboard .bread-crumb a i {
  background-color: $dashboard_main_back_color;
  color: $dashboard_text_color;
}

.dashboard .search-select-single option,
.dashboard .search-select-single input{
  color: $dashboard_text_color;
  background: $dashboard_main_back_color;
}

.dashboard .search-select-single input[type="date"]::-webkit-clear-button,
.dashboard .search-select-single input[type="date"]::-webkit-inner-spin-button,
.dashboard .search-select-single input[type="time"]::-webkit-clear-button,
.dashboard .search-select-single input[type="time"]::-webkit-inner-spin-button,
.dashboard .search-select-single input[type='time']::-webkit-calendar-picker-indicator,
.dashboard .search-select-single input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.dashboard .search-select-single,
.dashboard .search-select-single option:hover {
  background-color: $dashboard_main_back_color;
}

.dashboard .search-select-single {
  color: $dashboard_text_color;
  border-bottom-color: $dashboard_text_color;
}

.dashboard .highcharts-exporting-group,
.dashboard .highcharts-contextbutton {
  color: $dashboard_text_color;
  background-color: $dashboard_card_back_color;
}

.card-label,
.info-ribbon-text,
.card-detail,
.id-title,
.id-text {
  color: $fore_color_darkgray;
}

.form-control option {
  background-color: $header_table_color;
  color: $fore_color_darkgray;
}

//Table
.table {
  background-color: $back_color;
  border-color: $fore_color_gray;
}

tr.hovered-row:hover td {
  border-color: red;
}

.table td,
.table th {
  border-color: $fore_color_gray;
}

table.compact th {
  color: $fore_color_gray;
}

.table thead {
  background: $header_table_color;
}
//End table

//Export panel
.export-panel-header {
  background-color: $header_table_color;
  color: $fore_color_darkgray;
}

.export-panel-body {
  background-color: $back_color;
}

app-export-panel .form-group div {
  color: $fore_color_darkgray;
}

// .export-panel-widget {
//   box-shadow: rgba(0, 0, 0, 0.2);
// }
//End export panel

.selectBox select {
  border-color: $back_color_darkgray;
}

.touch-msg {
  color: #FF0000;
}

// Home + plan page
.page-wrapper-home .row.border-bottom {
  box-shadow: #0000000a;
}

.widget-txt-home {
  background-color: $back_color;
  color: $fore_color;
  border-color: #03B4F6; //The same color as the home buttons
}

.widget-txt-floors {
 background-color: $back_color;
}

.widget-home {
  color: $fore_color;
  box-shadow: rgba(0, 0, 0, 0.2);
}

.wrapper-building {
  background-color: $back_color;
}
// End homme + plan page

//Alert comments
.comments:nth-child(even) {
  background-color: $back_color_main !important;
}
.comments:nth-child(odd) {
  background-color: $back_color;
}

.comment-content {
  background-color: $back_color;
  border-color: $fore_color;
  color: $fore_color_darkgray;
}

.comment-date {
  color: $fore_color_gray;
}
//End alert comment

//Plan
.plan-list-title {
  background: #EEEEEE;
}

.plan-list {
  border-color: #DDDDDD;
  background: $back_color;
}

.plan-group-selected {
  border-color: red;
}
//End plan