@import "wt-skin-new";


.plan-container {
  width: fit-content;
  height: fit-content;
  margin: 0px;
  background-color: white;
  border: solid 2px #2D6C9B;
  font-size: 20px;
}